import request from '@/utils/request'


// 查询店铺标签列表
export function listShopLabel(query) {
  return request({
    url: '/shop/shop/label/list',
    method: 'get',
    params: query
  })
}

// 查询店铺标签分页
export function pageShopLabel(query) {
  return request({
    url: '/shop/shop/label/page',
    method: 'get',
    params: query
  })
}

// 查询店铺标签详细
export function getShopLabel(data) {
  return request({
    url: '/shop/shop/label/detail',
    method: 'get',
    params: data
  })
}

// 新增店铺标签
export function addShopLabel(data) {
  return request({
    url: '/shop/shop/label/add',
    method: 'post',
    data: data
  })
}

// 修改店铺标签
export function updateShopLabel(data) {
  return request({
    url: '/shop/shop/label/edit',
    method: 'post',
    data: data
  })
}

// 删除店铺标签
export function delShopLabel(data) {
  return request({
    url: '/shop/shop/label/delete',
    method: 'post',
    data: data
  })
}
